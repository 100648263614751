import React from "react";
import ReactDOM from 'react-dom'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

interface Props {
  carouselImages: string[]
  lightboxImages: string[]
  customPageImages: string[]
  adaptiveHeight: boolean
  detailUrl: string | null
  zoomable: boolean
}

interface State {
  currentIndex: number;
  showLightBox: boolean;
}

export default class Carousel extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      showLightBox: false
    }
  }

  renderItem = (image, index) => {
    return (
      <div key={index} className='item'>
        <img
          className='mainImage'
          src={image}
        />
      </div>
    );
  }

  renderCustomPaging = (i) => {
    return (
      <img className='dot' src={`${this.props.customPageImages[i]}`} />
    )
  }

  onCLickImage = (e) => {
    if (e.target.className == 'mainImage') {
      this.setState({
        showLightBox: true
      })
    }
  }

  renderSlider = () => {
    const { carouselImages } = this.props;
    const settings = {
      customPaging: this.renderCustomPaging,
      dots: carouselImages.length >= 2 ? true : false,
      dotsClass: "slick-dots slick-thumb",
      infinite: true,
      speed: 300,
      arrows: false,
      lazyLoad: true,
      adaptiveHeight: this.props.adaptiveHeight,
      beforeChange: (current, next) => {
        this.setState({
          currentIndex: next
        });
      }
    };

    return (
      <Slider {...settings} >
        {carouselImages.map((img, i) => this.renderItem(img, i))}
      </Slider>
    )
  }

  render() {
    const { detailUrl, zoomable, lightboxImages } = this.props;
    const { currentIndex } = this.state;
    return (
      <div>
        {/* ズームありカルーセル */}
        {zoomable && <a style={{ cursor: 'pointer' }} onClick={this.onCLickImage}>{this.renderSlider()}</a>}
        {zoomable && this.state.showLightBox &&
          <Lightbox
            mainSrc={lightboxImages[currentIndex]}
            onCloseRequest={() => this.setState({ showLightBox: false })}
            imagePadding={40}
          />
        }

        {/* 詳細ページへのリンクありカルーセル(streamなどで表示) */}
        {detailUrl && !zoomable && <a href={detailUrl}>{this.renderSlider()}</a>}

        {/* カルーセルだけ */}
        {!detailUrl && !zoomable && this.renderSlider()}

      </div>
    );
  }
}

/**
 * addEventListener
 */

document.addEventListener('DOMContentLoaded', () => {
  const nodes = document.getElementsByClassName('carousel')
  const nodesArr = Array.from(nodes);
  nodesArr.forEach((node) => {
    const carouselImagesText = node.getAttribute('carousel_images') || ''
    const carouselImages = carouselImagesText.split(',')

    const lightboxImagesText = node.getAttribute('lightbox_images') || ''
    const lightboxImages = lightboxImagesText.split(',')

    const customPageImagesText = node.getAttribute('custom_page_images') || ''
    const customPageImages = customPageImagesText.split(',')

    const adaptiveHeight = node.getAttribute('adaptive_height') == 'true' ? true : false;
    const zoomable = node.getAttribute('zoomable') == 'true' ? true : false;
    const detailUrl = node.getAttribute('detailUrl') || null;

    ReactDOM.render(
      <Carousel
        carouselImages={carouselImages}
        lightboxImages={lightboxImages}
        customPageImages={customPageImages}
        adaptiveHeight={adaptiveHeight}
        detailUrl={detailUrl}
        zoomable={zoomable}
      />,
      node
    )
  })

})

