import React, { useRef, useEffect, useState } from 'react'
import axios from 'axios'

// useRefを使いたいので関数コンポーネントを利用
// https://ja.reactjs.org/docs/components-and-props.html
// https://ja.reactjs.org/docs/hooks-state.html
// https://qiita.com/G4RDSjp/items/58364a6655d4968a90d9
// https://qiita.com/seya/items/6bbfa3f9d489809ccb2c
// https://qiita.com/tonio0720/items/c265b9b65db3bb76f2d3

const FavoritePopup = (props) => {

  const [endpoint] = useState(props.endpoint)
  const [users, setUsers] = useState([])
  const [isShown, setIsShown] = useState(false)

  const popupRef = useRef()
  const documentClickHandler = useRef()

  useEffect(() => {
    documentClickHandler.current = e => {      
      if (popupRef.current.contains(e.target)) return
      close()
    }
  }, [])

  const removeDocumentClickHandler = () => {    
    document.removeEventListener('click', documentClickHandler.current)
  }

  const close = () => {
    setIsShown(false)
    removeDocumentClickHandler()
  }

  const handleClick = () => {
    if (isShown) {
      close()
      return
    }

    axios.get(`${endpoint}/users`)
      .then((res) => {
        setUsers(res.data.users || [])
        setIsShown(true)
        document.addEventListener('click', documentClickHandler.current)
      })
      .catch((e) => {
        console.error(e)
      })
  }

  return (
    <div className='fav-popup-wrapper'>
      <div className='fav-popup-button' onClick={handleClick}>
        <img src='/images/fav-dots.svg' />
      </div>

      {(isShown && users.length > 0) &&
        <div ref={popupRef} className='fav-popup'>
          {users.map((user, index) => {
            return <a key={index} className='name' href={`/u/${user.identifier}/works`}>{user.name}</a>
          })}
        </div>
      }
    </div>
  )
}

export default FavoritePopup