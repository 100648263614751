import React from 'react'
import axios from 'axios'
import { MentionsInput, Mention } from 'react-mentions'
import { endpoint } from '../util/endpoint'
import BrLine from '../util/br_line'
import FavButton from '../favorites/favorite_button'
import { t } from '../util/locales'


let mentionUsers = []

const fetchUsers = (query, callback) => {
  if (!query) return
  if (mentionUsers.length > 0) {
    callback(searchUsers(query))
    return
  }
  axios.get(endpoint('mention/users'), { params: null })
    .then((res) => {
      mentionUsers = res.data
    })
    .then(callback(searchUsers(query)))
}

const searchUsers = (query) => {
  return mentionUsers.filter(user => user.display.toLowerCase().indexOf(query.toLowerCase()) > -1)
}


export default class Comments extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      data: props.comments || [],
      loaded: props.comments ? true : false,
      value: '',
      plainValue: '',
      mentions: [],
      edit_comment_id: 0,
    }
    this.bind()
  }

  bind = () => {
    this.configureParams = this.configureParams.bind(this)
    this.load = this.load.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleEditCancel = this.handleEditCancel.bind(this)
  }

  componentDidMount = () => {
    if (!this.state.loaded) {
      this.load()
    }
  }


  // API Access

  configureParams = () => {
    const { authenticity_token, work_id, news_id, post_id, event_id, square_article_id } = this.props

    let params = {
      authenticity_token: authenticity_token
    }
    if (work_id) {
      params['type'] = 'work'
      params['work_id'] = work_id

    } else if (news_id) {
      params['type'] = 'news'
      params['news_id'] = news_id

    } else if (post_id) {
      params['type'] = 'post'
      params['post_id'] = post_id

    } else if (event_id) {
      params['type'] = 'event'
      params['event_id'] = event_id

    } else if (square_article_id) {
      params['type'] = 'square_article'
      params['square_article_id'] = square_article_id
    }
    return params
  }

  load = () => {
    let params = this.configureParams()
    params['authenticity_token'] = null

    axios.get(endpoint('comments'), { params: params })
      .then((res) => {
        this.setState({ data: res.data.data })
      })
      .catch((e) => {
        console.log(e)
      })
  }

  create = (body, plainBody, users) => {
    const params = this.configureParams()
    params['body'] = body
    params['plain_body'] = plainBody
    params['mention_users'] = users
    axios.post(endpoint('comments'), params)
      .then((res) => {
        this.load()
      })
      .catch((e) => {
        window.alert(e)
      })
  }

  update = (body, id) => {
    const params = this.configureParams()
    params['body'] = body

    axios.put(endpoint(`comments/${id}`), params)
      .then((res) => {
        this.load()
        this.setState({
          edit_comment_id: 0,
          value: '',
          plainValue: '',
          mentions: []
        })
      })
      .catch((e) => {
        window.alert(e)
      })
  }

  delete = (comment_id) => {
    const params = this.configureParams()

    axios.delete(endpoint(`comments/${comment_id}`), { data: params })
      .then(() => {
        this.load()
      })
      .catch((e) => {
        window.alert(e)
      })
  }


  // Clip / Tap handling

  handleCreate = (event) => {
    event.preventDefault()
    const { value, plainValue, mentions } = this.state
    const users = mentions.map((users) => { return users.id })
    this.create(value, plainValue, users)

    this.setState({
      value: '',
      plainValue: '',
      mentions: []
    })
  }

  handleEdit = (event, comment) => {
    event.preventDefault()
    this.setState({
      edit_comment_id: comment.id,
      value: comment.raw_body,
      plainValue: '',
      mentions: []
    })
  }

  handleEditCancel = (event) => {
    event.preventDefault()
    this.setState({
      edit_comment_id: 0,
      value: '',
      plainValue: '',
      mentions: []
    })
  }

  handleUpdate = (event, comment) => {
    event.preventDefault()
    const { value } = this.state
    this.update(value, comment.id)
  }

  handleDelete = (event) => {
    event.preventDefault()
    const comment_id = event.target.getAttribute('comment-id')
    this.delete(comment_id)
  }


  // Render
  _renderComment = (comment) => {
    const { work_id, news_id, post_id, event_id, square_article_id } = this.props

    let parentType = ''
    if (work_id) { parentType = 'work' }
    if (news_id) { parentType = 'news' }
    if (post_id) { parentType = 'post' }
    if (event_id) { parentType = 'event' }
    if (square_article_id) { parentType = 'square_article' }

    return (
      <div key={comment.id} className='comment'>

        <div className='comment-block'>
          <img src={comment.user.image_url} />
          <div className='comment-block__body'>
            <a className='comment-block__user' href={`/u/${comment.user.identifier}/works`}>
              <BrLine text={comment.user.name} unescape={true} />
            </a>
            &nbsp;
            <BrLine text={comment.body} unescape={true} />
          </div>
        </div>

        <div className='comment-info'>
          <div>
            {comment.updated_at}
            {comment.user.deletable &&
              <span>
                &nbsp;&nbsp;
            <a href='#' onClick={(e) => this.handleEdit(e, comment)} comment-id={comment.id}>{t('edit')}</a>
                &nbsp;/&nbsp;
                <a href='#' onClick={this.handleDelete} comment-id={comment.id}>{t('delete')}</a>
              </span>
            }
          </div>

          <div className='comment-fav-button'>
            <FavButton
              target_id={comment.id}
              authenticity_token={this.props.authenticity_token}
              kind={'comment'}
              comment_parent_type={parentType}
              is_fav={comment.fav.is_fav}
              count={comment.fav.count} />
          </div>

        </div>
      </div>
    )
  }

  _renderEditForm = (comment) => {
    return (
      <div key={`edit_${comment.id}`} className='comment'>
        <div className='comment-block'>
          <img src={comment.user.image_url} />

          <div style={{ flex: 1 }}>
            {this._renderMentionInput()}

            <div style={{ marginTop: 10 }}>
              <input type='submit' value='Update' style={styles.editButton__update} onClick={(e) => this.handleUpdate(e, comment)} />
              <input type='submit' value='Cancel' style={styles.editButton__cancel} onClick={this.handleEditCancel} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  _renderMentionInput = () => {
    return (
      <MentionsInput
        value={this.state.value}
        allowSpaceInQuery={true}
        placeholder={t('ph_comment')}
        style={mentionStyle}
        onChange={(event, newValue, newPlainTextValue, mentions) => {
          this.setState({
            value: newValue,
            plainValue: newPlainTextValue,
            mentions: mentions
          })
        }}>
        <Mention
          trigger='@'
          markup='@[__display__](user:__id__)'
          data={fetchUsers}
          style={{ backgroundColor: '#EEF0F3' }} />
      </MentionsInput>
    )
  }

  render() {
    const { data, edit_comment_id } = this.state

    return (
      <div className='comments'>
        {data.map(comment => {
          if (edit_comment_id == comment.id) {
            return this._renderEditForm(comment)
          }
          return this._renderComment(comment)
        })}

        {/* コメント新規登録フォーム */}
        {edit_comment_id == 0 &&
          <form className='comment-form' onSubmit={this.handleCreate}>
            {this._renderMentionInput()}

            <div style={{ marginTop: 10 }}>
              <input type='submit' value={t('send')} />
            </div>
          </form>
        }
      </div>
    );
  }
}

const mentionStyle = {
  control: {
    backgroundColor: '#fff',
    fontSize: '1.3rem',
    fontWeight: 'normal'
  },

  highlighter: {
    overflow: 'hidden'
  },

  input: {
    margin: 0
  },

  '&multiLine': {
    control: {
      border: '1px solid silver'
    },

    highlighter: {
      padding: 9
    },

    input: {
      padding: 9,
      minHeight: 63,
      outline: 0,
      border: 0,
      overflow: 'auto',
      height: 70
    }
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: '1.3rem'
    },

    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#EEF0F3',
      }
    }
  }
}

const styles = {
  editButton__update: {
    marginRight: 15,
    width: 100
  },
  editButton__cancel: {
    width: 100
  }
}