// https://qiita.com/ttiger55/items/22e0f676ff6101336eaf

// URLパラメータをオブジェクト型で取得
export const getParams = (searchString) => { 
  if (searchString.length == 0) {
    return {}
  }
  return searchString.substring(1).split('&')
    .map((p) => p.split('=')).reduce((obj, e) => ({ ...obj, [e[0]]: e[1] }), {});
}

// パラメータ p の値を取得
export const getCurrentPage = (searchString) => {
  let p = getParams(searchString)['p']
  return p ? parseInt(p) : 1
}

// パラメータ p の値を書き換える
export const replacePage = (href, searchString, page) => {
  let base = href.replace(searchString, '')

  let params = getParams(searchString)
  params['p'] = page

  if (page == 1) {
    delete params['p']
  }

  if (Object.keys(params).length == 0) {
    return base
  }
  return base + '?' + Object.entries(params).map((e) => `${e[0]}=${e[1]}`).join('&');  
}

export default {
  getParams,
  getCurrentPage,
  replacePage
}