import React from 'react'

export default class BrLine extends React.Component {
  render() {
    const { text, unescape } = this.props

    return text.split("\n").map(function (line, index) {
      return (
        <span key={`line_${index}`}>
          {index > 0 && <br />}
          {unescape && <span dangerouslySetInnerHTML={{ __html: line }} />}
          {!unescape && line}
        </span>
      )
    })
  }
}