
export const masonryOptions = {
  columnWidth: '.grid-sizer',
  gutter: '.gutter-sizer',
  itemSelector: '.timeline-item',
  transitionDuration: '0.1s',
  percentPosition: true,
  horizontalOrder: true
}

export const masonryCollectionOptions = {
  columnWidth: '.col-grid-sizer',
  gutter: '.col-gutter-sizer',
  itemSelector: '.col-timeline-item',
  transitionDuration: '0.1s',
  percentPosition: true,
  horizontalOrder: true
}