import React from 'react'

import YouTube from 'react-youtube'
import Vimeo from '@u-wave/react-vimeo'


export default class VideoPlayer extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      errorMessage: ''
    }
  }

  render() {
    const { videos } = this.props
    const { errorMessage } = this.state

    let video = <React.Fragment />

    if (errorMessage != '') {
      return <div className='stream-video-error'>{errorMessage}</div>

    } else if (videos) {
      if (videos.vimeo) {
        video = <Vimeo video={videos.vimeo} width='100%' responsive={true} onError={(e) => {
          this.setState({
            errorMessage: e.message
          })
        }} />
      }
      if (videos.youtube) {
        video = <YouTube videoId={videos.youtube} opts={{ width: '100%'}} />
      }
    }
    return video
  }
}