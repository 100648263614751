import React from 'react'
import axios from 'axios'
import { endpoint } from '../util/endpoint'
import FavoritePopup from './favorite_popup'
import { t } from '../util/locales'


export default class FavButton extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      fav: props.is_fav,
      count: props.count
    }
    this.handleClick = this.handleClick.bind(this)
  }

  getUrl = () => {
    const { kind, target_id, comment_parent_type } = this.props
    if (kind == 'news') {
      return endpoint(`fav/news/${target_id}`)

    } else if (kind == 'post') {
      return endpoint(`fav/post/${target_id}`)

    } else if (kind == 'event') {
      return endpoint(`fav/event/${target_id}`)

    } else if (kind == 'comment') {
      return endpoint(`fav/comment/${comment_parent_type}/${target_id}`)

    } else {
      return endpoint(`fav/work/${target_id}`)
    }
  }

  handleClick() {
    const { authenticity_token } = this.props

    axios.post(this.getUrl(), { authenticity_token: authenticity_token })
      .then((res) => {
        this.setState({
          fav: res.status == 201,
          count: res.data.count
        })
      })
      .catch((e) => {
        console.error(e)
      })
  }
  
  render() {
    const { kind } = this.props
    const { count } = this.state

    // コメント用の省スペース表示
    if (kind == 'comment') {
      return (
        <div className={'fav-btn-wrapper'}>
          <button onClick={this.handleClick}>
            {this.state.fav ? '★' : '☆'}
            <span className='fav-count'>{count}</span>
          </button>
          {count > 0 && <FavoritePopup endpoint={this.getUrl()} />}
        </div>
      )
    }

    // 詳細画面用の通常表示
    return (
      <div className={'fav-btn-wrapper'}>
        <button onClick={this.handleClick}>
          {this.state.fav ? `★ ${t('favorite')}` : `☆ ${t('favorite')}`}
          {count > 0 && <span className='fav-count'>{count}</span>}
        </button>
        {count > 0 && <FavoritePopup endpoint={this.getUrl()} />}
      </div>
    )
  }
}