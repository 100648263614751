export const t = (key) => {
  return locale() == "ja" ? lang_ja[key] : lang_en[key]
}

const locale = () => {
  var cookies = document.cookie;
  var cookiesArray = cookies.split(';');

  for (var c of cookiesArray) {
    var cArray = c.split('=');
    if (cArray[0].trim() == 'locale') {
      return cArray[1];
    }
  }
  return 'en';
}


const lang_en = {
  artists_search_box_description: 'Search registered artist or Add new one from here.',
  ph_artist_name: 'Enter artist name',
  ph_provenance_name: 'Enter provenance name',
  title: 'Title',
  artist: 'Artist',
  ph_comment: 'input comment..',
  send: 'Send',
  favorite: 'Favorite',
  edit: 'edit',
  delete: 'delete',
  detail: 'detail',
  new_news: 'News',
  show_more: 'Show More',
  following: 'Following',
  follow: 'Follow',
  pickup_title: 'Today\'s pickup artist',
  notifications: 'Notifications',
  have_messages: 'You have new messages',
  show_more: 'Show more',
  work_count: 'works',
  search: 'Search',
  search_result_zero: 'Item Not Found',
  unread: 'unread',
  clear_all: 'Mark all as read',
  artists_list: 'More Artists',
  public_content: 'Public'
}

const lang_ja = {
  artists_search_box_description: '登録済みのアーティストを検索するか、ここから新しいアーティストを追加できます。',
  ph_artist_name: 'アーティスト名を入力して下さい',
  ph_provenance_name: '購入元ギャラリー',
  title: 'タイトル',
  artist: 'アーティスト',
  ph_comment: 'コメントを入力してください',
  send: '送信',
  favorite: 'お気に入り',
  stream__artist: 'アーティスト',
  edit: '編集',
  delete: '削除',
  detail: '詳細',
  new_news: '新着ニュース',
  show_more: 'もっと見る',
  following: 'フォロー中',
  follow: 'フォローする',
  pickup_title: '今日のピックアップアーティスト',
  notifications: 'お知らせ',
  have_messages: '未読メッセージがあります',
  show_more: 'もっと見る',
  work_count: '作品',
  search: '検索',
  search_result_zero: '該当するコンテンツはありません',
  unread: '未読',
  clear_all: '全て既読にする',
  artists_list: 'アーティスト一覧',
  public_content: '公開記事'
}