import React from 'react'
import ReactDOM from 'react-dom'
import { t } from './locales'


export default class ShowMore extends React.Component {

  render() {
    return (
      <div className="showmore-wrapper">
        <div className="showmore" onClick={this.props.onClick}>{t('show_more')}</div>
      </div>
    )
  }
}